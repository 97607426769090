.sidebar {
    background-color: #01bcb3;
    color: #FFF;
    margin-top: 50px;
    width: 220px;
}

.sidebar h4 {
    font-size: 1.3rem;
    vertical-align: sub;
    display: inline-block;
    width: auto;
    text-align: center;
    padding-top: 20px;
    padding-left: 0.3rem;
    margin: 0;
}

.menubar {
    padding: 0;
    list-style-type: none;
    background: transparent;
}

.menubar a,
.menubar a:link,
.menubar a:visited {
    color: var(--sidebar-item-color);
    text-decoration: none;
}

.menubar li {
    padding: 13px 25px;
    cursor: pointer;
    font-size: 14px;
}

.menubar li:hover,
.menubar .active li {
    color: var(--sidebar-item-color-active);
    background-color: var(--primary-color);
}

.sidebar svg.menu {
    display: inline-block;
    vertical-align: middle;
    border: 1px solid #000;
    border-radius: 5px;
    margin: 0.6rem;
    cursor: pointer;
    padding: 2px;
}

/*****Acordeon de usuarios*****/
.user-accordion .card {
    background-color: transparent !important;;
}

.user-accordion .card .card-header {
    font-size: 14px;
    padding-left: 23px;
    border: none;
    cursor: pointer;
    color: var(--sidebar-item-color-active);
}

.user-accordion .card ul {
    padding-left: 0;
    list-style-type: none;
}

.user-accordion .card .card-body {
    padding: 0;
}

.user-accordion .card .card-body li {
    padding: 13px 40px;
}

.user-accordion .card svg.down {
    padding: 1px;
    float: right;
}
.showSidebar{
    width: 40px;
    height: 40px;
    background-color: #01bcb3;
    position: absolute;
    top: 52px;
    border-radius: 0 0.7rem 0.7rem 0;
    box-shadow: 4px 4px 10px 1px rgb(51, 51, 51);
    padding: 7.5px;
}
.showSidebar svg.menu {
    display: inline-block;
    vertical-align: middle;
    border: 1px solid #000;
    border-radius: 5px;
    padding: 2px;
    cursor: pointer;
}

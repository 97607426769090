:root {
    --color: #232323;
    --disabled-color: #999;
    --bg-color: #f2f2f2;
    --primary-color: #278383;
    --secondary-color: #37b2b2;
    --thead-bg: #d6d4d4;
    --tr-border-color: #c3c3c3;
    --sidebar-item-color: #FFF;
    --sidebar-item-color-active: #FFF;
    --color-error: #E95858;
}

body, html {
    height: 100%;
    min-height: 100%;
}

body {
    font-family: 'Gotham';
    margin: 0;
    background: var(--bg-color);
    max-height: 100vh;
    height: 100%;
    width: 100%;
    max-width: 100%;
    padding: 0;
    font-size: 100%;
    color: var(--color);
}
#root {
    height: 100%;
    min-height: 100vh;
}

.content{
    width: 100%;
    display: flex; /* or inline-flex */
    height: 100%;
    max-height: 100vh;
}

.main {
    width: 100%;
    margin-top: 50px;
    max-height: 100vh;
    overflow: auto;
    padding: 15px;
}

.main h2 {
    margin-bottom: 2rem;
}
.main p {
    font-size: 14px;
}

.box {
    border: 1px solid var(--secondary-color);
    max-width: 80%;
    border-radius: 1rem;
    padding: 20px;
    margin: 0 auto 15px;
}

hr {
    border: .1rem solid #8dacac !important;
    margin: 1rem;
}

button.btn-normal {
    border: none;
    border-radius: 8px;
    color: #fff;
    padding: 5px 1rem;
    font-weight: 500;
    font-size: 14px;
}

button.ban{
    background: #e4ab00;
    color: #fff;
    margin: 1rem 0;
}

button.add{
    background: var(--primary-color);
    margin: 1rem 0;
}

button.space-btn{
    margin-right: 5px;
}

button.delete{
    background: #e40040;
    color: #fff;
    margin: 1rem 0;
}

button.activate{
    background: #3db33f;
    color: #fff;
    margin: 1rem 0;
}

input.search {
    border-radius: 20px;
    border: 1px solid #bbb;
    margin: 1rem 0;
    max-width: 240px;
    width: 240px;
    padding: 2px 15px 2px 2rem;
    font-size: 15px;
    background: no-repeat;
}

.search-icon {
    position: absolute;
    top: 20px;
    margin-left: 7px;
}

div.table-content {
    overflow-x: auto;
    max-height: 35rem;
    margin: 2rem 0;
    background-color: #fff;
    border-radius: 0.5rem;
    color: var(--color);
}

.table-content table {
    min-width: 500px;
    font-size: 14px;
    width: 100%;
    text-align: left;
    color: var(--color);
}

.table-content table tr {

}
.table-content table tr td:nth-child(1){
    text-transform: capitalize;
}
.table-content table tr td {
    padding: 10px 1.4rem;
    border-bottom: 1px solid #2ca6a642;
}

.table-content table tr th {
    padding: 8px 1.4rem;
    font-weight: 600;
    border-bottom: 2px solid #2ca6a663;
}
.table-content .dropTable{
    margin-left: 10px;
}
.alightCenter{
    text-align: center;
}
.alignRight {
    text-align: right;
}

.input-div {
    position: relative;
    padding: 1rem;
}

.input-div label,
.input-radio label.label-title {
    display: block;
    font-size: 15px;
}

.select {
    max-height: 40px;
    text-align: center;
    border: none;
    border-bottom: .1rem solid var(--secondary-color);
    background: transparent;
    font-size: 15px;
    padding: .5rem;
}
.select option{
    font-size: 90%;
}

.input-div input {
    display: block;
    width: 95%;
    border: none;
    border-bottom: .1rem solid var(--secondary-color);
    font-size: 15px;
    padding: 7px 5px;
}
.input-div textarea {
    border: none;
    font-size: 15px;
    padding: 7px 5px;
    width: 95%;
    max-height: 5rem;
    min-height: 5rem;
    border: .1rem solid var(--secondary-color);
}
.input-error {
    border-bottom: 2px solid var(--color-error) !important;
}

.text-error-class {
    color: var(--color-error);
    font-weight: 500;
    font-size: 11px;
    position: absolute;
    bottom: 0px;
    left: 5px;
}

.form-control {
    /*height: calc(1.5em + .75rem + 0px) !important;*/
    background-color: rgba(255, 255, 255, 0.5) !important;
    /*border: none !important;*/
}

.form-check-label {
    margin-bottom: 0;
    padding-left: 5px;
    margin-top: 3px;
}

.loader {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 9;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background: transparent;
    cursor: progress;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loader-inside {
    z-index: 9;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background: transparent;
    cursor: progress;
    width: 100%;
    height: 100%;
    text-align: center;
    margin-top: 5%;
    border: solid 1px rgba(0, 0, 0, 0.2);
}

@media (max-height: 500px) {
    .login {
        max-width: 500px;
        margin: 0 auto;
    }
}

.react-datepicker__time-container {
    border-left: 1px solid #aeaeae;
    width: 85px;
    position: fixed !important;
    right: -90px !important;
}

.react-datepicker__input-container input {
    text-align: center;
}

.react-datepicker-wrapper{
    width: 100%;
}

.file-head{
    width: 100%;
    max-width: 150px;
    position: absolute;
    top: 1rem;
    left: 1rem;
}

.red-row td{
    color: red;
}

.yellow-row td {
    color: rgb(160 160 8)
}
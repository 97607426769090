nav.navbar{
    background-color: #01bcb3;
    height: 60px;
    color: #FFF;
    z-index: 1;
    padding: .5rem 0;
}
.navbar img{
    width: 120px;
    height: auto;
    padding-left: 1rem;
}
.navbar.navbar-dark .navbar-nav .nav-link{
    color: #FFF;
}
.navbar.navbar-dark .navbar-nav .nav-link:hover{
    color: #F3F3F3;
}

.navbar.navbar-dark .navbar-nav .dropdown.nav-item{
    padding-right: 1rem;
}
nav.navbar .navbar-collapse{
    background-color: #01bcb3;
    padding: 0.5rem 1rem;
}

.navbar-dark .navbar-nav .nav-link{
    color: #FFF !important;
}
.nav-link:hover{
	color: #ddd;
}
.dropdown{
	display: inline-block !important;
}
.dropdown-menu{
    right: 0px !important;
    left: unset !important;
}

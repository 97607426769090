h4.client{
	padding-top: 2rem;
    text-transform: capitalize;
}
.subtitle-6{
    margin-top: 2rem;
}
.client-click:hover{
    color: var(--secondary-color);
    cursor: pointer;
}
.btn-add-div{
    text-align: right;
    padding-right: 2rem;
}
.btn-add-div .dropdown-toggle::after {
	display: none;
}
.btn-add-div .dropdown .dropdown-toggle{
	border-radius: 50%;
	font-weight: 500;
}
.btn-add-div .dropdown .dropdown-menu{
	transform: none !important;
	top: 40px !important;
	border: none;
	background: transparent;
	padding: 0px;
}
.btn-add-div .dropdown .dropdown-menu .dropdown-item {
	padding: 0rem 0.5rem !important;
}
.btn-add-div .dropdown .dropdown-menu .dropdown-item:focus,
.btn-add-div .dropdown .dropdown-menu .dropdown-item:visited{
	background: none !important;
}
.radio-label{
    padding: 0 0.5rem;
}
h4.client{
	padding-top: 2rem;
    text-transform: capitalize;
}
.content-routines tr.clientes-body td:nth-child(4){
	max-width: 200px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.btn-add-div{
    text-align: right;
    padding-right: 2rem;
}
.label-cl{
	text-transform: uppercase;
	font-size: 13px;
	color: #737373;
	padding-left: 0.5rem;
	padding-top: 1rem;
}
.input-cl{
	border: 1.4px solid #737373;
	border-radius: 6px;
	height: 2rem;
	padding: 3px 10px;
	font-size: 15px;
}
.textarea-cl {
    border-radius: 6px;
    padding: 3px 10px;
    font-size: 15px;
    min-height: 20rem;
	max-height: 25rem;
	overflow-y: auto;
	border: 1.4px solid #737373;
}
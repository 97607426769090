.input-filtros{
    border: 1px solid #ced4da;
}
.reporte{
    margin: 0 15px !important;
}
.reporte-element{
}
.reporte-element div{
    border: 1px solid #ced4da;
    border-radius: 0.7rem;
    padding: 15px;
    text-align: center;
}
.reporte-element div h3{
    font-size: 1.5em;
}
.reporte-element div h4{
    font-size: 1.3em;
}
.reporte-element div p{
    font-size: 1.8em;
}
.swal2-content{
    display: flex;
    max-height: 400px;
}

.swal-height {
    width:auto !important;
}



  
 
  
  .table-fixed thead {
    width: 97%;
  }
  .table-fixed tbody {
    height: 230px;
    overflow-y: auto;
    width: 100%;
  }
  .table-fixed thead, .table-fixed tbody, .table-fixed tr, .table-fixed td, .table-fixed th {
    display: block;
  }
  .table-fixed tbody td, .table-fixed thead > tr> th {
    float: left;
    border-bottom-width: 0;
  }